<template>
  <div>
    <b-modal size="lg" id="modal-center1" hide-header title="">
      <template #modal-footer>
        <div class="footerBtn">
          <b-button class="footerBtnSolo" size="sm" v-b-modal.modal-center2>
            Cancelar</b-button
          >
          <b-button
            class="footerBtnOk"
            @click="saveOrUpdateboxFront"
            v-if="!dataSale.id"
            size="sm"
          >
            Confirmar
          </b-button>
          <b-button
            class="footerBtnOk"
            v-b-modal.modal-center
            v-if="dataSale.id"
            size="sm"
          >
            Imprimir / Emitir NFC-e
          </b-button>
        </div>
      </template>
      <div class="titleSketch">
        <span>RESUMO DA VENDA </span>
        <div class="situationContent">
          <div
            v-if="situationSelect && situationSelect.nome === 'Faturado(a)'"
            style="background: green"
            class="situationBall"
          ></div>
          <div
            v-if="situationSelect && situationSelect.nome === 'Finalizado(a)'"
            style="background: blue"
            class="situationBall"
          ></div>
          <div
            v-if="situationSelect && situationSelect.nome === 'Cancelado(a)'"
            style="background: red"
            class="situationBall"
          ></div>
          <div
            v-if="situationSelect && situationSelect.nome === 'Em aberto'"
            style="background: gray"
            class="situationBall"
          ></div>
          <div class="situation">
            {{
              situationSelect && situationSelect.nome
                ? situationSelect.nome
                : ""
            }}
          </div>
        </div>
        <div @click="$bvModal.hide('modal-center1')" class="closeModal">
          <b-icon-x scale="1.3"></b-icon-x>
        </div>
      </div>
      <div class="SummaryForm">
        <div class="containerForm">
          <div class="generalForm">
            <!-- <div class="selectPlus">
              <label class="fixedSelect">Cliente:</label>
              <b-form-select
                class="borderInput shadow-none"
                value-field="id"
                text-field="nome"
                :options="dataCustomers"
                v-model="dataReceive.idCliente"
              >
              </b-form-select>
            </div> -->

            <div>
              <InputInfinit
                label="Cliente"
                :isEdit="!!dataReceive.idCliente"
                :dataInput.sync="dataReceive.idCliente"
                :onSearch="fillCustomerComboBoxWithPaginate"
                :openCreateModal="() => openModals('modalCreateCustomer')"
                :defaultLabel="false"
                :enableIconCreate="false"
                @selectItem="handleDataCustomers($event)"
              />
            </div>

            <div class="selectPlus" style="display: none">
              <label class="fixedSelect">Indicador de presença:</label>
              <b-form-select
                class="borderInput shadow-none"
                value-field="value"
                v-model="dataNfce.presenca"
                :options="presenca"
                disabled
              >
              </b-form-select>
            </div>

            <div class="selectPlus" style="display: none">
              <label class="fixedSelect">Condição de pagamento:</label>
              <b-form-select
                value-field="value"
                text-field="text"
                v-model="selected"
                disabled
                class="borderInput shadow-none"
                :options="dataPagamento"
                size="sm"
              >
              </b-form-select>
            </div>

            <div class="selectPlus">
              <label class="fixedSelect">Forma de pagamento:</label>
              <b-form-select
                value-field="id"
                text-field="tipo"
                class="borderInput shadow-none"
                :options="typeFormPayment"
                v-model="dataReceive.idFormaDePagamento"
                size="sm"
                @change="selectPayment"
              >
              </b-form-select>
            </div>
            <div class="selectPlus">
              <label class="fixedSelect">Conta de Recebimento:</label>
              <InputSelect
                valueField="id"
                textField="nome"
                :items="allAccountReceiptBills"
                :dataSelect.sync="dataReceive.idContaRecebimento"
                :isDisabled="this.blocked"
                :openCreateModal="() => openModals('modalContaRecebimento')"
                ref="refContaDeRecebimento"
              />
            </div>
            <div class="employee">
              <div class="selectPlus">
                <label class="fixedSelect">Funcionário:</label>
                <b-form-select
                  class="borderInput shadow-none"
                  size="sm"
                  value-field="id"
                  text-field="nomeFuncionario"
                  :options="dataEmployee"
                  v-model="dataReceive.idFuncionario"
                  @change="calculateComissao"
                >
                </b-form-select>
              </div>
              <div
                style="
                  position: relative;
                  margin-bottom: 0px;
                  font-size: 11px;
                  z-index: 2;
                  text-transform: capitalize;
                  color: rgb(85, 81, 81);
                  background-color: transparent;
                  font-weight: 600;
                "
              >
                <label class="fixedSelect"> Comissão: </label>

                <b-form-input
                  style="
                    width: 95px;
                    height: 28px;
                    font-size: 11px;
                    padding: 0 10px;
                    text-align: end;
                    border: 1px solid #d5d3d3;
                  "
                  disabled
                  :value="maskCurrency(dataSale.comissao)"
                  @input="calculateComissaoFormatted($event)"
                  class="shadow-none"
                ></b-form-input>
              </div>
            </div>
          </div>
          <div v-if="typeSearch" class="secondPay">
            <div class="contentSecondPay">
              <div class="selectPlus">
                <label class="fixedSelect"> 2° Forma de pagamento:</label>
                <b-form-select
                  value-field="id"
                  text-field="tipo"
                  class="borderInput shadow-none"
                  :options="typeFormPayment"
                  v-model="dataReceive.idFormaDePagamentoSecond"
                  size="sm"
                  @change="selectPaymentSecond"
                >
                </b-form-select>
              </div>

              <div class="selectPlus">
                <label class="fixedSelect"> 2° Conta de Recebimento:</label>
                <InputSelect
                  valueField="id"
                  textField="nome"
                  :items="allAccountReceiptBills"
                  :dataSelect.sync="dataReceive.idContaRecebimentoSecond"
                  :openCreateModal="() => openModals('modalContaRecebimento')"
                  ref="refContaDeRecebimento2"
                />
              </div>

              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 30px;
                "
              >
                <label class="totalsValues"> Valor recebido (R$) </label>

                <b-form-input
                  style="width: 105px"
                  :value="calculateFirstPayment"
                  disabled
                  class="styleInput shadow-none"
                ></b-form-input>
              </div>
            </div>
          </div>
        </div>

        <span v-if="!cashierEntry" class="checkInNotice"
          >Ao selecionar está opção a entrada no caixa fica em aberto!</span
        >
        <div class="formPayment">
          <b-form-group v-if="!dataSale.id" class="launchPayment">
            <label
              v-if="!this.blocked"
              style="
                margin: 0 !important;
                height: 30px;
                display: flex;
                align-items: center;
              "
            >
              Lançar como pago no caixa?
            </label>
            <label
              style="
                position: absolute;
                right: 15px;
                top: 5px;
                bottom: 0;
                height: 30px;
              "
              class="switch"
              v-if="!this.blocked"
            >
              <input type="checkbox" v-model="cashierEntry" />
              <div class="slider">
                <span>Não</span>
                <span>Sim</span>
              </div>
            </label>
          </b-form-group>

          <div
            v-if="this.typeSearch"
            style="display: flex; justify-content: space-between"
          >
            <label class="totalsValues" style="font-weight: bold">
              Valor 1° Pagamento
            </label>

            <b-form-input
              style="width: 105px"
              class="styleInput shadow-none"
              :value="maskCurrency(secondPayment)"
              @input="calculateValue($event)"
              ref="valor"
            ></b-form-input>
          </div>

          <div style="display: flex; justify-content: space-between">
            <label class="totalsValues"> Subtotal (R$) </label>

            <b-form-input
              style="width: 105px"
              disabled
              v-model="subtotal"
              class="styleInput shadow-none"
            ></b-form-input>
          </div>

          <div style="display: flex; justify-content: space-between">
            <label class="totalsValues"> Desconto </label>

            <b-form-input
              style="width: 105px"
              disabled
              v-model="amountDesc"
              class="styleInput shadow-none"
            ></b-form-input>
          </div>

          <p class="divider"></p>

          <div style="display: flex; justify-content: space-between">
            <label class="totalsValues"> Total da Venda (R$) </label>

            <b-form-input
              style="width: 105px"
              disabled
              v-model="amount"
              ref="amount"
              class="styleInput shadow-none"
            ></b-form-input>
          </div>

          <div
            v-if="this.blocked"
            style="display: flex; justify-content: space-between"
          >
            <label class="totalsValues"> Valor recebido (R$) </label>

            <b-form-input
              style="width: 105px"
              class="styleInput shadow-none"
              :value="maskCurrency(received)"
              @input="calculateSubtotal($event)"
            ></b-form-input>
          </div>

          <div
            v-if="this.blocked"
            style="display: flex; justify-content: space-between"
          >
            <label class="totalsValues"> troco (R$) </label>

            <b-form-input
              style="width: 105px"
              class="styleInput shadow-none"
              :value="calculateRemaining"
              disabled
            ></b-form-input>
          </div>
        </div>
        <div style="margin-top: -20px">
          <label class="cyberpunk-checkbox-label">
            <input
              type="checkbox"
              v-model="typeSearch"
              class="cyberpunk-checkbox"
              @click="blockSecondPayment"
            />
            Informar outra forma de pagamento</label
          >
        </div>
        <span v-if="dataSale.id" class="checkInUpdate"
          >Não é possivel atualizar uma venda com duplicatas pagas! Remova e
          tente novamente!</span
        >
      </div>
      <b-modal
        id="modal-center"
        hide-footer
        hide-header
        centered
        title="Emitir"
      >
        <div>
          <h6 class="titleModal">Deseja Emitir ?</h6>
          <div class="btnModal">
            <button class="btnContentReceive" @click="printReportCoupon">
              Recibo Simples
            </button>

            <button
              v-if="isStoneIntegration && enableReceivePayment"
              class="btnContent"
              @click="generateOrderInMachine"
            >
              Receber Pagamento
            </button>

            <button
              v-else
              class="btnContent"
              @click="
                isStoneIntegration
                  ? checkPaymentIsFinished(
                      'Cupom Fiscal enviado para a ordem na maquininha',
                      true
                    )
                  : sendCoupon()
              "
            >
              Emitir NFC-e
            </button>
          </div>

          <a class="rejectModal" v-if="!spinLoading" @click="closeAllModals"
            >Não, Obrigado!</a
          >
        </div>
        <Loading :text="textToModalLoading" v-if="spinLoading" class="spin" />
      </b-modal>

      <b-modal
        id="modal-center2"
        hide-footer
        hide-header
        centered
        title="Emitir"
      >
        <div>
          <h6 class="titleModal">Deseja iniciar uma nova venda ?</h6>
          <div class="btnModal">
            <button class="btnContent" @click="clearAllData()">
              Nova venda
            </button>
            <button
              class="btnContentReceive"
              @click="$bvModal.hide('modal-center2')"
            >
              Não, Obrigado!
            </button>
          </div>
          <span class="importantWarning"
            >*** Ao iniciar uma nova venda os dados preenchidos serão
            perdidos!</span
          >
        </div>
      </b-modal>
    </b-modal>
    <ModalCreateAccountReceiptBill
      @getNewReceiptBill="createdNewAccountReceiptBill = $event"
    />
    <ModalAwaitPayment
      :idSale="dataSale.id"
      @cancelPayment="closeModal('ModalAwaitPayment')"
    />
  </div>
</template>
<script>
import api from "../../services/axios";
import moment from "moment";
import { mapState, mapMutations } from "vuex";
import { initialStateNfeNfce } from "../../initialStates/nfeNfce";

import Loading from "../Loading/Loading.vue";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";
import InputInfinit from "../../components/UI/CustomInputs/InputInfinit.vue";
import ModalCreateAccountReceiptBill from "../Modals/ModalCreateAccountReceiptBill.vue";
import ModalAwaitPayment from "../Modals/ModalAwaitPayment.vue";

import serviceCustomer from "../../services/serviceCustomer";
import serviceSale from "../../services/serviceSale";
import serviceEmployees from "../../services/serviceEmployees";
import ServiceNotaFiscal from "../../services/serviceNotaFiscal";
import ServiceMachine from "../../services/serviceMachine";
import ServiceAccountReceiptBill from "../../services/serviceAccountReceiptBill";
import servicePaymentForm from "../../services/servicePaymentForm";

import printReport from "../../reports/sale";
import serviceSituation from "../../services/serviceSituation";
import { SaleTypeSituation } from "../../utils/enums/SalesSituationType";
import { HandleErrors } from "../../utils/handleErrors";
import { TypeErrors } from "../../utils/enums/TypeErrors";
import { mascaraMoeda, maskCurrency } from "../../utils/maskMoney";
import { OrderMachineStatus } from "../../utils/enums/OrderMachineState";
import { formatObjectToSaveAndUpdate } from "../../helpers/saleAndBudget";
import { pagamento, formaPagamento } from "../../common/index";

export default {
  components: {
    Loading,
    InputSelect,
    ModalCreateAccountReceiptBill,
    ModalAwaitPayment,
    InputInfinit,
  },
  props: {
    dataSale: {
      type: Object,
    },
    amountDesc: {
      type: String,
    },
    amount: {
      type: String,
    },
    subtotal: {
      type: String,
    },
  },
  data() {
    return {
      blocked: false,
      spinLoading: false,
      typeSearch: false,
      dataNfce: initialStateNfeNfce,
      dataPagamento: pagamento,
      selected: 0,
      dataFormPagamento: formaPagamento,
      received: 0,
      returnDataSale: [],
      presenca: [{ value: "1", text: "Operação presencial" }],
      dataCustomers: [],
      dataEmployee: [],
      allAccountReceiptBills: [],
      createdNewAccountReceiptBill: null,
      typeFormPayment: [],
      situationSelect: [],
      situationOfSale: [],
      cashierEntry: true,
      secondPayment: 0,
      dataReceive: {
        idCliente: "",
        idFuncionario: "",
        idContaRecebimento: "",
        idFormaDePagamento: "",
        idFormaDePagamentoSecond: "",
        idContaRecebimentoSecond: "",
      },
      responseNfeWebMania: {
        chave: "",
        danfe: "",
        danfe_etiqueta: "",
        danfe_simples: "",
        log: "",
        modelo: "",
        motivo: "",
        nfe: "",
        serie: "",
        status: "",
        uuid: "",
        xml: "",
      },
      maskCurrency: maskCurrency,
      isMachineOrder: false,
      enableReceivePayment: true,
      isShowToastConfirmPayment: false,
      textToModalLoading: "Aguarde! O Seu cupom está sendo emitido !",
    };
  },
  methods: {
    ...mapMutations([
      "ALTER_SIZE_MODAL",
      "ALTER_TYPE_DOCUMENT",
      "SET_DATA_SEARCH_FROM_BOX_FRONT",
      "SET_COLAPSE_PRINT",
      "ALTER_VALUES_MODAL_DIALOG_GENERAL",
      "SET_INTERVAL_MACHINE_ID",
    ]),
    async saveOrUpdateboxFront() {
      if (!this.dataSale.id) return this.save();
      this.updateCompletedSale();
    },

    async save() {
      try {
        this.dataSale.idCliente = this.dataReceive.idCliente;
        this.dataSale.idFuncionario = this.dataReceive.idFuncionario;
        const situationSelected = this.situationOfSale.find(
          (situation) => situation.nome === "Finalizado(a)"
        );
        this.dataSale.idSituacao = situationSelected.id;
        this.handleFormattedBills();
        if (this.typeSearch) {
          this.handleFormattedBillsSecond();
        }
        const dataSaleFormatted = formatObjectToSaveAndUpdate(this.dataSale);
        this.returnDataSale = await serviceSale.createNewCompletedSale(
          dataSaleFormatted
        );
        this.dataSale.id = this.returnDataSale.id;
        this.situationSelect = situationSelected;

        this.openModals("modal-center");
        return this.$toast.open({
          message: "Venda salva com Sucesso",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);
        if (!this.dataConfig.caixa_automatico.utilizar) {
          if (
            typeError &&
            Object.keys(typeError).length &&
            TypeErrors.NO_SAFE_REGISTER_OPEN.includes(typeError?.nameError)
          ) {
            return this.handleModalSafeOpening();
          }
        }

        return this.$toast.open({
          message:
            typeError?.message ||
            error?.response?.data?.message ||
            "Ocorreu um erro ao salvar o frente de caixa",
          type: "info",
        });
      }
    },

    async updateCompletedSale() {
      try {
        this.handleFormattedBills();
        if (this.typeSearch) {
          this.handleFormattedBillsSecond();
        }
        const dataSaleFormatted = formatObjectToSaveAndUpdate(this.dataSale);
        await serviceSale.updateCompletedSale(dataSaleFormatted);

        this.openModals("modal-center");
        return this.$toast.open({
          message: "Venda atualizada com Sucesso",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (!this.dataConfig.caixa_automatico.utilizar) {
          if (
            typeError &&
            Object.keys(typeError).length &&
            TypeErrors.NO_SAFE_REGISTER_OPEN.includes(typeError?.nameError)
          ) {
            return this.handleModalSafeOpening();
          }
        }
        console.log(error);
        return this.$toast.open({
          message: typeError?.message || error.response.data.message,
          type: "info",
        });
      }
    },

    blockSecondPayment() {
      this.blocked = !this.blocked;
    },

    async generateOrderInMachine() {
      try {
        await ServiceMachine.createOrder(this.returnDataSale.id);

        this.startPaymentInMachine();
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao criar a ordem para a maquininha",
          type: "info",
        });
      }
    },

    async startPaymentInMachine() {
      this.$bvModal.show("ModalAwaitPayment");

      const id = setInterval(this.checkPaymentIsFinished, 5000);
      this.SET_INTERVAL_MACHINE_ID(id);
    },

    async checkPaymentIsFinished(
      toastMessag = "Pagamento realizado com sucesso!",
      repeatToast = false
    ) {
      try {
        this.spinLoading = true;
        this.textToModalLoading =
          "Aguarde! O Seu cupom está sendo enviado para a maquininha !";

        const result = await ServiceMachine.checkIsPaidAndGenerateDocument(
          this.returnDataSale.id
        );

        if (result?.status === OrderMachineStatus.PAID) {
          clearInterval(this.intervalMachineId);
          this.SET_INTERVAL_MACHINE_ID("");

          this.$bvModal.hide("ModalAwaitPayment");
          this.enableReceivePayment = false;

          if (repeatToast) {
            this.$toast.open({
              message: toastMessag,
              type: "success",
            });
          }

          if (!repeatToast && !this.isShowToastConfirmPayment) {
            this.$toast.open({
              message: toastMessag,
              type: "success",
            });
          }

          this.isShowToastConfirmPayment = true;
        }
      } catch (error) {
        clearInterval(this.intervalMachineId);
        this.SET_INTERVAL_MACHINE_ID("");
        this.$bvModal.hide("ModalAwaitPayment");

        const typeError = HandleErrors(
          error?.response?.data?.message === "Validation failed"
            ? error?.response?.data?.erros
            : error?.response?.data?.message
        );

        if (repeatToast) {
          this.$toast.open({
            message: "Ocorreu um problema com a emissão do Cupom fiscal",
            type: "info",
          });
        } else if (
          typeError &&
          typeError?.nameError === TypeErrors.ITEM_NF_INVALID
        ) {
          this.enableReceivePayment = false;

          this.$toast.open({
            message: `Pagamento realizado, porém ocorreu um erro com emissão do documento fiscal. 
              <br />  
              <div style="color: yellow!important">ERRO: ${typeError.message}</div>
            `,
            type: "info",
          });
        } else {
          this.$toast.open({
            message:
              typeError?.message ||
              "Ocorreu um problema ao efetuar o pagamento!",
            type: "info",
          });
        }
      } finally {
        this.spinLoading = false;
        this.textToModalLoading = "Aguarde! O Seu cupom está sendo emitido !";
      }
    },

    async checkPaymentIsPending() {
      try {
        const { status, machine } = await ServiceMachine.checkOrder(
          this.returnDataSale.id
        );

        if (status === OrderMachineStatus.PENDING && machine) {
          this.startPaymentInMachine();
        } else if (status === OrderMachineStatus.CANCELED) {
          this.enableReceivePayment = true;
        } else if (status === OrderMachineStatus.PAID) {
          this.enableReceivePayment = false;
        }
      } catch (error) {
        console.log(error);
        this.$toast.open({
          message: "Ocorreu um problema ao efetuar o pagamento!",
          type: "info",
        });
      }
    },

    async sendCoupon() {
      try {
        this.spinLoading = true;
        this.textToModalLoading = "Aguarde! O Seu cupom está sendo emitido !";

        this.dataSale.idSituacao = this.situationSelect.id;
        const data = await ServiceNotaFiscal.sendCoupon(this.returnDataSale.id);
        this.responseNfeWebMania.danfe = data.dataWebMania.danfe;
        this.visualizarNfe();
        if (data.dataWebMania.status === "aprovado") {
          this.situationSelect = this.situationOfSale.find(
            (situation) => situation.nome === SaleTypeSituation.faturado
          );
        }

        this.$toast.open({
          message: "Cupom Fiscal emitido com sucesso!",
          type: "success",
        });
      } catch (error) {
        this.situationSelect = this.situationOfSale.find(
          (situation) => situation.nome === SaleTypeSituation.finalizado
        );

        const typeError = HandleErrors(error?.response?.data?.message);

        if (typeError && typeError?.nameError === TypeErrors.NO_PERMISSIONS) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        return this.$toast.open({
          message: error?.response?.data?.message,
          type: "warning",
        });
      } finally {
        this.spinLoading = false;
        this.textToModalLoading = "Aguarde! O Seu cupom está sendo emitido !";
      }
    },

    visualizarNfe() {
      window.open(
        this.responseNfeWebMania.danfe,
        "_blank",
        "width=700, height=800, top=500, left=500"
      );
    },

    handleFormattedBills() {
      this.dataSale.bills[0].idCliente = this.dataReceive.idCliente;
      this.dataSale.bills[0].idFuncionario = this.dataReceive.idFuncionario;
      this.dataSale.bills[0].idContaRecebimento =
        this.dataReceive.idContaRecebimento;
      this.dataSale.bills[0].idFormaPagamento =
        this.dataReceive.idFormaDePagamento;
      if (this.typeSearch) {
        this.dataSale.bills[0].valorRestante = this.secondPayment;
        this.dataSale.bills[0].valorTotal = this.secondPayment;
      } else {
        this.dataSale.bills[0].valorRestante = mascaraMoeda(this.amount);
        this.dataSale.bills[0].valorTotal = mascaraMoeda(this.amount);
      }
      this.dataSale.bills[0].dataCompetencia = moment().format("YYYY-MM-DD");

      if (this.cashierEntry) {
        this.dataSale.bills[0].dataPagamento = moment().format("YYYY-MM-DD");
      } else {
        this.dataSale.bills[0].dataPagamento = null;
      }
    },

    handleFormattedBillsSecond() {
      const second = {
        idCliente: this.dataReceive.idCliente,
        tipo: "entrada",
        idVenda: this.dataSale.idVenda,
        idFuncionario: this.dataReceive.idFuncionario,
        idContaRecebimento: this.dataReceive.idContaRecebimentoSecond,
        idFormaPagamento: this.dataReceive.idFormaDePagamentoSecond,
        valorTotal: (mascaraMoeda(this.amount) - this.secondPayment).toFixed(2),
        valorRestante: (mascaraMoeda(this.amount) - this.secondPayment).toFixed(
          2
        ),
        valorPago: 0,
        dataCompetencia: moment().format("YYYY-MM-DD"),
        data: moment().format("YYYY-MM-DD"),
      };

      if (this.cashierEntry) {
        second.dataPagamento = moment().format("YYYY-MM-DD");
      } else {
        second.dataPagamento = null;
      }

      const differenceValue =
        mascaraMoeda(this.amount) -
        (mascaraMoeda(second.valorTotal) + this.secondPayment);
      if (differenceValue.toFixed(2) !== 0) {
        console.log(differenceValue);
        // em algumas situações dá um valor muito louco por isso nao atribui esse valor ao valor total , deve-se conferir o pq do valor vim tão zuado
      }
      this.dataSale.bills.push(second);
    },

    async printReportCoupon() {
      printReport("reportCoupon", this.returnDataSale.id);
      this.closeAllModals();
    },

    clearAllData() {
      this.$emit("clearAll", true);
      this.dataSale.id = "";
      this.dataReceive.idCliente = "";
      this.dataReceive.idContaRecebimento = "";
      this.dataReceive.idFormaDePagamento = "";
      this.dataReceive.idContaRecebimentoSecond = "";
      this.dataReceive.idFormaDePagamentoSecond = "";
      this.dataReceive.idFuncionario = "";
      this.dataSale.idFuncionario = "";
      this.secondPayment = 0;
      this.typeSearch = false;
      this.$bvModal.hide("modal-center1");
    },

    calculateSubtotal(event) {
      this.received = mascaraMoeda(event);
    },

    calculateValue(event) {
      this.secondPayment = mascaraMoeda(event);
    },

    calculateComissaoFormatted(event) {
      this.exibitionComissao = mascaraMoeda(event);
    },

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },

    handleModalSafeOpening() {
      this.ALTER_VALUES_MODAL_DIALOG_GENERAL({
        nameModal: "modalDialogSafeOpening",
        title: "Caixa ainda não aberto!",
        subTitle:
          "Verificamos que você ainda não iniciou o caixa, para realizar qualquer lançamento, é necessario realizar a abertura do caixa!",
        footerTitle: "Deseja abrir o caixa agora ?",
        leftButtonText: "Não",
        rightButtonText: "Sim",
      });

      this.$nextTick(() => {
        this.openModals(this.dataModalDialogGeneral.nameModal);
      });
    },

    async fillCustomerComboBoxWithPaginate(id, q) {
      try {
        return await serviceCustomer.fillComboBoxWithPaginate(id, q);
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao listar os clientes",
          type: "info",
        });
      }
    },

    handleDataCustomers(event) {
      this.dataCustomers = [event];
    },

    async getAllReceiptBill() {
      const result = await ServiceAccountReceiptBill.fillComobobox();
      this.allAccountReceiptBills = result;
    },

    async getAllPayment() {
      const result = await servicePaymentForm.getAllPayment();
      this.typeFormPayment = result.sort((a, b) => a.cod - b.cod);
    },

    async getAllSituation() {
      this.situationOfSale = await serviceSituation.getAllSituation();
      this.situationSelect = this.situationOfSale.find(
        (situation) => situation.nome === "Em aberto"
      );
      if (this.dataSale.id) {
        this.situationSelect = this.situationOfSale.find(
          (situation) => situation.nome === "Finalizado(a)"
        );
      }
    },

    async listEmployeesSelectBox() {
      try {
        const { data } = await api.get("/employees/combobox/fill");
        this.dataEmployee = data.data;
      } catch (error) {
        console.log(error.response);
      }
    },

    async calculateComissao() {
      const data = await serviceEmployees.findById(
        this.dataReceive.idFuncionario
      );
      if (data.comissao) {
        const total =
          parseFloat(data.comissao) * (mascaraMoeda(this.amount) / 100);
        this.dataSale.comissao = total.toFixed(2);
      }
    },

    async assignReceivingAccount() {
      await this.$nextTick();
      this.$refs.refContaDeRecebimento.valueItems =
        this.dataSale.bills[0].idContaRecebimento;
    },
    async assignReceivingAccountSecond() {
      await this.$nextTick();
      this.$refs.refContaDeRecebimento2.valueItems =
        this.dataSale.bills[1].idContaRecebimento;
    },
    selectPayment() {
      this.blocked = false;
      const moneyPayment = this.typeFormPayment.find(
        (formPayment) => formPayment.id === this.dataReceive.idFormaDePagamento
      );
      if (moneyPayment.tipo === "Dinheiro") {
        this.blocked = true;
        const accountPayment = this.allAccountReceiptBills.find(
          (account) => account.nome === "CAIXA GAVETA"
        );
        this.dataReceive.idContaRecebimento = accountPayment.id;
        this.$refs.refContaDeRecebimento.valueItems = accountPayment.id;
      }
    },

    selectPaymentSecond() {
      const moneyPayment = this.typeFormPayment.find(
        (formPayment) =>
          formPayment.id === this.dataReceive.idFormaDePagamentoSecond
      );
      if (moneyPayment.tipo === "Dinheiro") {
        const accountPayment = this.allAccountReceiptBills.find(
          (account) => account.nome === "CAIXA GAVETA"
        );
        this.dataReceive.idContaRecebimentoSecond = accountPayment.id;
        this.$refs.refContaDeRecebimento2.valueItems = accountPayment.id;
      }
    },

    closeAllModals() {
      this.$bvModal.hide("modal-center");
      this.$bvModal.hide("modal-center1");
      this.clearAllData();
    },

    closeModal(name) {
      this.$bvModal.hide(name);
    },
  },

  mounted() {
    this.listEmployeesSelectBox();
    this.getAllReceiptBill();
    this.getAllPayment();
    this.getAllSituation();
    this.$nextTick(() => {
      if (this.dataSale.id) {
        this.$bvModal.show("modal-center1");
        this.returnDataSale.id = this.dataSale.id;
        this.dataReceive.idCliente = this.dataSale.idCliente;
        this.dataReceive.idFuncionario = this.dataSale.idFuncionario;
        this.situationSelect.id = this.dataSale.idSituacao;
        if (this.dataSale.bills[0]) {
          this.dataReceive.idContaRecebimento =
            this.dataSale.bills[0].idContaRecebimento;
          this.dataReceive.idFormaDePagamento =
            this.dataSale.bills[0].idFormaPagamento;
          this.assignReceivingAccount();
          if (this.dataSale.bills[0].dataPagamento === null) {
            this.cashierEntry = false;
          }
        }
        if (this.dataSale.bills[1]) {
          this.typeSearch = true;
          this.secondPayment = this.dataSale.bills[1].valorPago;
          this.dataReceive.idContaRecebimentoSecond =
            this.dataSale.bills[1].idContaRecebimento;
          this.dataReceive.idFormaDePagamentoSecond =
            this.dataSale.bills[1].idFormaPagamento;
          this.assignReceivingAccountSecond();
        }

        if (this.isStoneIntegration) this.checkPaymentIsPending();
      }
    });
  },

  computed: {
    ...mapState({
      dataModalDialogGeneral: (state) => state.dataModalDialogGeneral,
      dataSafeIsOpen: (state) => state.dataSafeIsOpen,
      dataConfig: (state) => state.dataConfig,
      intervalMachineId: (state) => state.intervalMachineId,
      dataCompany: (state) => state.dataCompany,
    }),
    calculateRemaining() {
      return (this.received - mascaraMoeda(this.amount)).toLocaleString(
        "pt-br",
        {
          style: "currency",
          currency: "BRL",
        }
      );
    },
    calculateFirstPayment() {
      return (mascaraMoeda(this.amount) - this.secondPayment).toLocaleString(
        "pt-br",
        {
          style: "currency",
          currency: "BRL",
        }
      );
    },
    safeIsOpen() {
      return this.dataSafeIsOpen.safeIsOpen;
    },
    isStoneIntegration() {
      if (
        this.dataCompany?.liberars[0]?.integracoes &&
        Object.keys(this.dataCompany?.liberars[0]?.integracoes).length
      ) {
        const stone =
          this.dataCompany?.liberars[0]?.integracoes.cardMachines.find(
            (machine) => machine.text === "STONE"
          );

        return stone.value;
      }

      return false;
    },
  },

  watch: {
    createdNewAccountReceiptBill() {
      this.getAllReceiptBill();
    },
    safeIsOpen() {
      this.saveOrUpdateboxFront();
    },
  },
};
</script>

<style scoped>
.titleSketch {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
  border-bottom: 1px solid rgb(214, 214, 214);
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  margin-right: 10px;
}

.titleSketch span {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.selectPlus {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect {
  position: absolute;
  margin-bottom: 0px;
  font-size: 11px;
  bottom: 30px;
  left: 0px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: transparent;
  font-weight: 600;
}

.employee {
  display: flex;
  gap: 20px;
}

.cyberpunk-checkbox {
  appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid #555151;
  border-radius: 5px;
  background-color: transparent;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.cyberpunk-checkbox:before {
  content: "";
  background-color: #00486e;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 8px;
  height: 8px;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.cyberpunk-checkbox:checked:before {
  transform: translate(-50%, -50%) scale(1);
}

.cyberpunk-checkbox-label {
  font-size: 12px;
  color: black;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}

.secondPay {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 12px;
  width: 100%;
  padding: 10px 20px 0px 20px;
  border-radius: 10px;
  margin-top: -10px;
  background: #fdfdfd;
  border: 2px dotted #cfcfcf;
}

.contentSecondPay {
  display: flex;
  flex-direction: column;
  gap: 25px;
  font-size: 12px;
  width: 100%;
  padding: 10px 0px 0px 0px;
  border-radius: 10px;
  margin-top: 7px;
  background: #fdfdfd;
}

.secondPay span {
  font-weight: bold;
  font-size: 12px;
  color: rgb(85, 81, 81);
}

.custom-select:disabled {
  color: black;
  background-color: #d5d3d3;
}

.custom-select {
  background: none;
  text-transform: capitalize;
}

.borderInput {
  border-radius: 3px;
  height: 28px;
  font-size: 12px;
  padding: 0 10px;
  border: 1px solid #d5d3d3;
}
.borderInputSituation {
  border-radius: 3px;
  height: 28px;
  font-size: 12px;
  padding: 0 10px;
  border: none;
  width: 100px;
}

.closeModal {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(255, 153, 153);
  cursor: pointer;
  border: 1px solid rgb(238, 238, 238);
  padding: 2px 3px;
  border-radius: 4px;
}

.closeModal:hover {
  color: red;
  background: rgba(245, 245, 245, 0.764);
}

.situationContent {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  margin-right: 20px;
}

.situationBall {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.footerBtn {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.footerBtnSolo {
  background: #ff2c16;
  width: 33%;
  border: none;
  font-size: 11px;
  height: 28px;
}

.footerBtnOk {
  width: 33%;
  font-size: 11px;
  height: 28px;
  background: whitesmoke;
  border: 1px solid green;
  color: green;
  border-radius: 3px;
}

.SummaryForm {
  display: flex;
  flex-direction: column;
  color: rgb(85, 81, 81);
  padding: 0px 10px;
  gap: 25px;
  margin-top: 20px;
}

.containerForm {
  display: flex;
  color: rgb(85, 81, 81);
}

.generalForm {
  display: flex;
  flex-direction: column;
  color: rgb(85, 81, 81);
  padding: 0px 10px;
  gap: 25px;
  margin-top: 20px;
  width: 100%;
  transition: all 3s ease-in-out;
}

.formPayment {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 40%;
  font-size: 11px;
  margin-top: -15px;
}

.input-group-text {
  background: rgb(85, 81, 81);
  color: #fff;
}

.form-group {
  margin-bottom: 0rem !important;
}

.styleInput {
  height: 25px;
  font-size: 11px;
  padding: 0 10px;
  text-align: end;
  border: 1px solid #d5d3d3;
}

.divider {
  border-bottom: 2px dotted #d5d3d3;
}

.spinnerStyled {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: red;
}

/*  Modal confirmação  */

.titleModal {
  font-size: 12px;
  border-bottom: 1px solid #e6e7e8;
}

.btnModal {
  margin-top: 10px;
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.btnContent {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentReceive {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.rejectModal {
  cursor: pointer;
  color: black;
  display: flex;
  justify-content: center;
  font-size: 11px;
  font-style: oblique;
  text-decoration: underline;
}

.importantWarning {
  color: red;
  font-size: 10px;
  display: flex;
  justify-content: center;
}

.spin {
  width: 480px;
  height: 100%;
  top: 0;
}

.checkInNotice {
  color: #951a1a;
  font-size: 11px;
  margin-top: -20px;
  margin-bottom: -20px;
  display: flex;
  justify-content: center;
  cursor: context-menu;
}

.checkInUpdate {
  color: #951a1a;
  font-size: 11px;
  display: flex;
  text-align: center;
  cursor: context-menu;
  margin: -10px 0;
}

/* switch */

.switch {
  font-size: 12px;
  position: relative;
  display: inline-block;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #cccaca;
  color: #504d4d;
  border-radius: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.4s;
  width: 65px !important;
  height: 80%;
}

.slider:before {
  position: absolute;
  content: "Não";
  height: 100%;
  width: 45%;
  left: 5%;
  border-radius: 50%;
  background-color: rgb(66, 203, 66);
  color: whitesmoke;
  font-weight: bold;
  display: grid;
  align-content: center;
  justify-content: center;
  transition: 0.4s;
}

.slider:after {
  content: "";
  position: absolute;
  z-index: -1;
}

.switch input:checked + .slider {
  background-color: #cccaca;
  color: #504d4d;
}

.switch input:checked + .slider:before {
  content: "Sim";
  background-color: rgb(66, 203, 66);
  transform: translateX(100%);
  color: white;
}

.switch input {
  display: none;
}

.situation {
  cursor: context-menu;
  font-family: Poppins-Regular, sans-serif !important;
}

.launchPayment {
  height: 30px;
  position: relative;
  margin-top: -10px;
  margin-bottom: 5px !important;
  color: #00486e;
}

.launchPayment label {
  font-weight: 600;
}

.totalsValues {
  display: flex;
  align-items: center;

  margin: 0 !important;
}
</style>