export const mascaraMoeda = (value, decimalPlaces = 2) => {
  const onlyDigits = String(value)
    .split("")
    .filter(s => /\d/.test(s))
    .join("")
    .padStart(decimalPlaces, "0")

  const digitsFloat = onlyDigits.slice(0, -decimalPlaces) + "." + onlyDigits.slice(-decimalPlaces)
  return parseFloat(digitsFloat)
}

export const maskCurrency = (valor, locale = 'pt-BR', currency = 'BRL') => {
  if (isNaN(valor)) {
    valor = 0
  }
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency
  }).format(valor)
}

export const maskAmount = (valor, decimalPlaces = 2) => {
  if (!valor) {
    valor = 0;
  }

  return parseFloat(valor)
    .toLocaleString("pt-BR", {
      minimumFractionDigits: decimalPlaces || 2,
    })
    .replace(",", ".");
}